<template>
  <div id="profile-page">
    <div class="page-header profile-header row w-100 align-items-end mx-0 mb-4">
      <div class="title col-12 col-lg-8 mx-auto pb-2">
        <h3 class="text-white">{{ $t("telestia.title.profile") }}</h3>
      </div>
    </div>

    <!-- Offline version -->
    <div v-if="isOfflineBuild" class="row mx-0">
      <div class="col-12 col-lg-8 mx-auto">
        <h4 class="mb-2">{{ $t("telestia.message.welcome") }} {{ user.username }}</h4>

        <div class="row my-3">
          <div class="col-2 p-2" v-for="course in user.courses" :key="course.id">
            <div class="course-panel w-100 h-100">
		          <span class="text-link mouse-pointer text-underline" @click="navigateToCourse(course.key)">{{ course.title }}</span>
		          <span class="howtostudy-icon mouse-pointer ml-2" @click="navigateToCourseInstructions(course.key)">?</span>
		        </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Hybrid, online version -->
    <template v-else>

		  <!-- Loading state -->
		  <div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center">
		    <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
		  </div>

		  <!-- Loaded state -->
		  <template v-else>

				<!-- Active announcement section -->
				<div v-if="activeAnnouncements.length > 0" class="row mx-2 mx-lg-0">
	        <div v-if="showAnnouncement" class="col-12 col-lg-8 mx-auto py-2 px-3 mb-4 border rounded shadow shadow-sm">
	        	<div class="d-flex justify-content-between align-items-center mouse-pointer" @click="showAnnouncement = false">
	        		<span class="font-weight-bold">{{ $t('telestia.title.announcements') }}</span>
	        		<font-awesome-icon :icon="['fas', 'chevron-up']"/>
	        	</div>
	        	<div class="divider-light mt-2 mb-3"></div>
	        	<div class="d-flex flex-column justify-content-center align-items-start position-relative">
              <div class="announcement-wrapper">
                <h5 v-html="activeAnnouncements[currentAnnouncementIndex].title" class="announcement-title font-weight-bold"></h5>
                <div v-html="activeAnnouncements[currentAnnouncementIndex].text" class="announcement-text"></div>
              </div>

	        		<template v-if="activeAnnouncements.length > 1">
			      		<div class="carousel-nav-container h-100 position-absolute d-flex flex-column visible" style="left:-40px">
			      			<span class="carousel-nav-btn p-2 mouse-pointer" @click="changeCurrentAssignmentIndex(-1)">
			      				<font-awesome-icon :icon="['fas', 'chevron-left']" />
			      			</span>
			      		</div>
			      		<div class="carousel-nav-container h-100 position-absolute d-flex flex-column visible" style="right:-40px">
			      			<span class="carousel-nav-btn p-2 mouse-pointer" @click="changeCurrentAssignmentIndex(1)">
			      				<font-awesome-icon :icon="['fas', 'chevron-right']" />
			      			</span>
			      		</div>

			      		<div v-if="activeAnnouncements.length > 1" class="text-center w-100">
									<span v-bind:key="index"
										v-for="(announcement, index) in activeAnnouncements"
										class="dot"
										:class="{ 'current': currentAnnouncementIndex === index }"
										@click="currentAnnouncementIndex = index"
									></span>
								</div>
							</template>
	        	</div>
	        </div>

	        <div v-else class="col-12 col-lg-8 mx-auto d-flex justify-content-start py-2 px-3 mb-4">
	        	<a class="mouse-pointer font-weight-bold" @click="showAnnouncement = true">{{ $t('telestia.title.announcements') }}</a>
            <b-badge variant="warning">
              <font-awesome-icon :icon="['fas', 'exclamation']" class="text-black" />
            </b-badge>
	        </div>
	      </div>

	    	<!-- Account section -->
	      <div class="row mx-0">
	        <div class="col-12 col-lg-8 mx-auto">
            <div class="row mx-0">
              <div class="col-12 col-lg-6 mx-auto">
                <h5 class="mb-2 font-weight-bold">{{ $t("telestia.title.account") }}</h5>
                <div class="mb-1">
                  <label class="mr-3 mb-1">{{ $t("telestia.title.username") }}: </label>
                  <span>{{ user.username }}</span>
                </div>
                <div class="mb-1">
                  <label class="mr-3 mb-1">{{ $t("telestia.title.password") }}: </label>
                  <button class="primary-btn px-2" v-b-modal.password-form>{{ $t("telestia.button.change_password") }}</button>
                </div>
                <div  class="mb-1">
                  <label class="mr-3 mb-1">{{ $t("telestia.title.registered_from") }}: </label>
                  <span>{{ user.registeredFrom }}</span>
                </div>
                <policy policy="personaldata" class="mb-1"/>
                <policy policy="mediaconsent" class="mb-1"/>
                <hr>
                <div  class="mb-1" v-if="hasExpiredCourses">
                  <label class="mr-3">
                    <span class="mr-1"><font-awesome-icon :icon="['fas', 'graduation-cap']" style="color:#1a96cf;width:22px;height:auto"/></span>
                    {{ hasExpiredCourses }}
                    {{ $t('telestia.navigation.courses') }}:
                  </label>
                  <span class="mouse-pointer" @click="scrollToRenewal">
                    {{ $t('telestia.profile.renewal_available') }}
                    <span class="primary-btn p-1 ml-1"><font-awesome-icon :icon="['fas', 'arrow-down']"/></span>
                  </span>
                </div>
                <!--
                <div class="mb-1">
                  <label class="mr-3">{{ $t("telestia.title.expires_at") }}: </label>
                  <span>{{ user.expiresAt }}</span>
                </div>
                -->
              </div>
              <div class="col-12 col-lg-6 mx-auto">
                <h5 class="mb-2 font-weight-bold">{{ $t("telestia.title.welcome") }}</h5>
                <video id="welcome-video" controls playsinline disablepictureinpicture oncontextmenu="return false;" style="max-height:200px;max-width:100%"
                  controlsList="nodownload nofullscreen noplaybackrate"
                  :poster="`https://www.fashionexpresslearning.com/videos/welcome-${selectedLanguage}.png`"
                  :src="`https://www.fashionexpresslearning.com/videos/welcome-${selectedLanguage}.mp4`"
                  >
                </video>
              </div>
            </div>
          </div>
	      </div>

	      <div class="divider w-100"></div>

				<!-- Courses section -->
	      <div id="courses-section" class="row mx-0">
	        <div class="col-12 col-lg-8 mx-auto">
	          <h5 class="mb-2 font-weight-bold">{{ $t("telestia.title.my_courses") }}</h5>

	          <div class="row mb-2">
	            <div class="col">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t("telestia.table.header.course") }}</th>
                        <th>{{ $t("telestia.table.header.tutor") }}</th>
                        <th class="text-center">{{ $t("telestia.table.header.progress") }}</th>
                        <th class="text-center">{{ $t("telestia.table.header.assignments") }}</th>
                        <template v-if="!user.isAdmin">
                          <template v-if="isOnlineBuild">
                            <th>{{ $t("telestia.table.header.course_access") }}</th>
                            <th>{{ $t("telestia.table.header.next_installment") }}</th>
                            <th>{{ $t("telestia.table.header.access_plan_end") }}</th>
                          </template>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="course in user.courses" :key="course.id" class="align-middle">
                        <td :class="{ 'disabled': !user.isAdmin && isOnlineBuild && needsRenewal(course.user_courses) }" >
                          <span
                          	class="text-link mouse-pointer"
                          	:class="{ 'text-underline': !(!user.isAdmin && isOnlineBuild && needsRenewal(course.user_courses)) }"
                          	@click="navigateToCourse(course.key)"
                          >{{ course.title }}</span>
                          <span class="howtostudy-icon mouse-pointer ml-2" @click="navigateToCourseInstructions(course.key)">?</span>
                        </td>
                        <template>
                        	<td v-if="isObjEmpty(course.tutor) || (!course.tutor.f_name && !course.tutor.l_name)">--</td>
                        	<td v-else>{{ `${course.tutor.f_name}${course.tutor.f_name ? ' ' : ''}${course.tutor.l_name}` }}</td>
                        </template>
                        <template v-if="course.meta">
                          <td class="text-center">{{ course.meta.downloads }}/{{ course.meta.total_lessons }}</td>
                          <td class="text-center">{{ course.meta.assignments }}/{{ course.meta.total_assignments }}</td>
                        </template>
                        <template v-else>
                          <td class="text-center">--</td>
                          <td class="text-center">--</td>
                        </template>
                        <template v-if="!user.isAdmin && isOnlineBuild">
                          <td>
                            {{ course.user_courses.expDate }}
                            <button v-if="needsRenewal(course.user_courses)" class="renewal-button primary-btn btn-sm ml-2" @click="renewCourseAccess(course)">
                              {{ $t('telestia.profile.renew') }}
                            </button>
                          </td>
                          <td>{{ course.user_courses.subDate ? course.user_courses.expDate : '--' }}</td>
                          <td v-if="course.user_courses.subDate">
                            {{ course.user_courses.subDate }}<br>
                            {{ `(${course.user_courses.subMonths} ${$t('telestia.profile.months')})` }}
                          </td>
                          <td v-else>--</td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
	            </div>
	          </div>
	        </div>
	      </div>

        <you-may-also-like :usercourses="user.courses"/>

        <personal-measurements v-if="!isOfflineBuild" :measurements="user.measurements" :measurementError="measurementError"/>

      </template>

      <b-modal id="password-form" centered scrollable size="lg" hide-header-close @hidden="resetPasswordForm">
		    <template #modal-header>
		    	<div class="w-100 d-flex justify-content-between align-items-center">
            <h5>{{ $t("telestia.title.change_password") }}</h5>
          </div>
        </template>

		    <template #default>
				  <!-- Change password modal fields -->
          <template>
            <div v-for="field in Object.keys(passwordForm)" :key="field">
              <div class="mb-2 row d-flex align-items-center">
                <label :for="field" class="col-sm-4 col-form-label">{{ $t(`telestia.title.${passwordForm[field].label}`) }}:</label>
                <div class="col-sm-8">
                  <div class="d-flex align-items-center" id="show_hide_password">
                    <input :type="passwordForm[field].show ? 'text' : 'password'" class="form-control" :id="field" v-model="passwordForm[field].value">
                    <font-awesome-icon
                      :icon="['fas', passwordForm[field].show ? 'eye-slash' : 'eye']"
                      class="mouse-pointer ml-2"
                      @click="passwordForm[field].show = !passwordForm[field].show"
                    />
                  </div>

                  <template v-if="field === 'newRepeat'">
                  	<small v-if="passwordForm[field].value && !newPasswordsMatch" class="mt-1">{{ $t("telestia.message.passwords_do_not_match") }}</small>
                  </template>
                </div>
              </div>
            </div>
				  </template>
		    </template>

		    <template #modal-footer="{ hide }">
          <div>
		        <button class="primary-btn py-2 px-3 mr-2" :class="{ 'disabled': !passwordsFilled || !newPasswordsMatch }" @click="resetPassword({ hide })">
		        	{{ $t("telestia.title.change_password") }}
		        </button>
            <button class="primary-btn p-2" @click="hide()">{{ $t("telestia.button.cancel") }}</button>
		      </div>
		    </template>
		  </b-modal>
    </template>
  </div>
</template>

<script>
import md5 from "md5";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import Policy from './Policy.vue';
import PersonalMeasurements from './PersonalMeasurements.vue';
import YouMayAlsoLike from './YouMayAlsoLike.vue';

export default {
  name: "profile",

  components: {
    Policy,
    PersonalMeasurements,
    YouMayAlsoLike,
  },

  created() {
    this.deselectProductCourse();
    this.fetchProfile();
  },

  data() {
    return {
      pendingComponentInit: false,

      user: {
        username: '',
        password: '',
        isAdmin: false,
        registeredFrom: '',
        expiresAt: '',
        courses: [],
        measurements: [],
      },
      showAnnouncement: false,
      currentAnnouncementIndex: 0,
      activeAnnouncements: [],
      measurementError: '',

      passwordForm: {
        current: { label: 'current_password', value: '', show: false },
        new: { label: 'new_password', value: '', show: false },
        newRepeat: { label: 'repeat_password', value: '', show: false }
      },
    }
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },
    isHybridBuild() { return this.$store.getters['app/isHybrid'] },
    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    selectedLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

    passwordsFilled() { return this.passwordForm.current.value && this.passwordForm.new.value && this.passwordForm.newRepeat.value; },
    newPasswordsMatch() { return this.passwordForm.new.value === this.passwordForm.newRepeat.value; },

    // returns number of expired courses
    hasExpiredCourses(){
      return this.user.courses?.filter((c)=>{return new Date() > Date.parse(c?.user_courses?.expDate||Infinity)}).length;
    },
  },

  methods: {
  	changeCurrentAssignmentIndex(step) {
    	if (this.currentAnnouncementIndex + step < 0) { step = (this.activeAnnouncements.length - 1) * step; }
    	this.currentAnnouncementIndex = Math.abs((this.currentAnnouncementIndex + step) % this.activeAnnouncements.length);
  	},

    // Set product, course, lesson options to null
    deselectProductCourse() {
      if (this.$store.getters.getSelected.productKey) {
        this.selectMenu({ menu1Index: null, menu2Index: null, lessonKey: null });
        this.$store.commit('selectProductCourse', { productKey: null, courseKey: null });
      }
    },

    // Fetch user profile
    async fetchProfile() {
      try {
        this.pendingComponentInit = true;
        this.user.username = this.$store.getters['user/getUsername'];
        this.user.isAdmin = this.$store.getters['user/isAdmin'];
        this.user.registeredFrom = this.$store.getters['user/getRegistrationDate'];
        this.user.expiresAt = this.$store.getters['user/getExpirationDate'];

        if (!this.isOfflineBuild) {
        	await this.$store.dispatch('userCourses/fetchUserCourses');

          this.user.measurements = await this.$store.dispatch('measurements/fetchUserMeasurements');
          const userOrgId = this.$store.getters['user/getOrgId']
          this.activeAnnouncements = await this.$store.dispatch('announcements/fetchActiveAnnouncements', userOrgId);
        }

      } catch (e) {
        console.log(e);
        if (!e.ignore) { this.measurementError = 'Measurements could not be retrieved.. Something went wrong'; }
      } finally {
        this.user.courses = await this.getUserCourses();
        this.pendingComponentInit = false;
      }
    },

    async getUserCourses() {
      let userCourses = [];
      // Hybrid or online build
      if (!this.isOfflineBuild) {
        userCourses = this.$store.getters['userCourses/getCourses'];
        userCourses = _sortBy(userCourses, ['cdId', 'order']);
        if (userCourses.length!==0) return userCourses.map(c => { return { ...c, title: this.$store.getters['userCourses/getCourseTitle'](c.key) } });
      }
      if (this.isOfflineBuild || (this.isHybridBuild && userCourses.length===0)) {
        let userProducts = await this.$store.dispatch('getProductCourses');
        userProducts.forEach(p => {
          const courses = p.courses.map(c => { return { key: c.ckey, title: c.ctitle, order: c.order, cdId: p.pid } });
          userCourses = userCourses.concat(courses)
        });
        return _sortBy(userCourses, ['cdId', 'order']);
      }
    },

    // Helper function that return true if an obj is empty
    isObjEmpty(obj) {	return _isEmpty(obj) },

    // Helper function that returns true if a user's course need renewal
    needsRenewal(course) { return this.courseExpired(course) },

    renewCourseAccess(course) {
   		const username = this.$store.getters['user/getUsername'];
			const selectedUserLanguage = this.$store.getters['user/getSelectedLanguage'];
			const courseCd = this.$store.getters['products/getCds'].find(cd => cd.id = course.cdId).cd;
			const courseTitle = course.translations.find(tr => tr.language === 'en').title;
			const courseTitleTranslated =
				(course.translations.find(tr => tr.language === selectedUserLanguage) || course.translations.find(tr => tr.language === 'en')).title;
			const md5Token = md5(username + courseCd + course.id + 'omCCZZvmbiM-251510');

			const coursesSection = document.getElementById('courses-section');
			coursesSection.innerHTML +=
				`<form id="course-renewal-form" action="https://renewal.fashionexpresspatterns.com/" method="post" target="_blank" style="display: none">
					<input type="text" name="lang" value="${selectedUserLanguage}">
					<input type="text" name="username" value="${username}">
					<input type="text" name="email" value="${this.$store.getters['user/getEmail']}">
					<input type="text" name="org_id" value="${this.$store.getters['user/getOrgId']}">
					<input type="text" name="f_name" value="${this.$store.getters['user/getFirstName']}">
					<input type="text" name="l_name" value="${this.$store.getters['user/getLastName']}">
					<input type="text" name="cd" value="${courseCd}">
					<input type="text" name="course_id" value="${course.id}">
					<input type="text" name="course_title" value="${courseTitle}">
					<input type="text" name="course_title_translated" value="${courseTitleTranslated}">
					<input type="text" name="token" value="${md5Token}">
				</form>`;
			const courseRenewalForm = document.getElementById('course-renewal-form');
			courseRenewalForm.submit();
			coursesSection.removeChild(courseRenewalForm);
   	},

    // Helper function that returns true if a user's course is expired
    courseExpired(course) { return new Date() > Date.parse(course.expDate) },

    // Helper function that returns true if a user's course has no active subscriptions
    noActiveSubscription(course) { return !course.subDate && !course.subMonths },

    // Navigate to selected course
    async navigateToCourse(courseKey) {
    	try {
    		this.$store.dispatch("app/toggleCourseSelectionState");

    		const productKey = await this.selectCourse(courseKey);
    		this.$router.push({ name: 'App:Lessons', params: { product_key: productKey.toLowerCase(), course_key: courseKey.toLowerCase() } });
    	} catch (e) {
    		console.log(e);
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.course') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
    	} finally {
    		setTimeout(() => this.$store.dispatch("app/toggleCourseSelectionState"), 1000);
    	}
    },

    // Navigate to selected course's instructions
    async navigateToCourseInstructions(courseKey) {
    	try {
    		this.$store.dispatch("app/toggleCourseSelectionState");

    		await this.selectCourse(courseKey);
    		this.$router.push({ name: 'App:HowToStudy' });
    	} catch (e) {
    		console.log(e);
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.instructions') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
    	} finally {
    		this.$store.dispatch("app/toggleCourseSelectionState");
    	}
    },

    // Select product and course course
    async selectCourse(courseKey) {
    	try {
    		// Select product and course
    		let selectedProductKey = '';
    		if (this.isOnlineBuild) {
    			const products = await this.$store.dispatch('products/fetchProducts');
    			const selectedProductId = this.user.courses.find(c => c.key === courseKey).cdId;
    			selectedProductKey = products.find(p => p.id === selectedProductId).cd.toUpperCase();
    		} else {
    			const products = await this.$store.dispatch('getProductCourses');
    			selectedProductKey = products.find(p => p.courses.findIndex(c => c.ckey === courseKey) > -1).pkey;
    		}

        // In case of online build, fetch product skeleton from server
        if (this.isOnlineBuild) { await this.$store.dispatch("products/fetchProductSkeleton", selectedProductKey.toLowerCase()); }

        this.$store.commit('selectProductCourse', { productKey: selectedProductKey.toLowerCase(), courseKey });

    		// Initialize menu options
		    this.selectMenu({ lessonKey: null, menu1Index: 0, menu2Index: 0 });

		    return Promise.resolve(selectedProductKey);
    	} catch (e) {
    		return Promise.reject(e);
    	}
    },

    // items is an object with possible properties: menu1Index, menu2Index, lessonKey
    selectMenu(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = this.isObjEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },

    // Reset password form
    resetPasswordForm() {
    	// Reset form fields
      Object.keys(this.passwordForm).forEach(k => {
        if (this.passwordForm[k].type === 'checkbox') { this.passwordForm[k].value = false; }
        else { this.passwordForm[k].value = ''; }
      });
    },

    async resetPassword({ hide }) {
      try {
        await this.$store.dispatch('user/updatePassword', { currentPassword: this.passwordForm.current.value, newPassword: this.passwordForm.new.value });
        this.showAlert({ title: this.$t('telestia.profile.change_password'), text: '', icon: 'success' });
      } catch(e) {
        console.log(e.response);
        if (e.response.data.code === "auth/invalid-current-password") {
          this.showAlert({ title: this.$t('telestia.error.password'), text: this.$t('telestia.error.try_again'), icon: 'error' });
        } else {
          this.showAlert({
            title: this.$t('telestia.error.title'),
            text: this.$t('telestia.error.change_password') + "\n" + this.$t('telestia.error.connection'),
            icon: 'error',
          });
        }
      } finally {
        hide();
      }
    },

    showAlert({ title, text, icon }) {
    	this.$swal({ title, text, icon, timer: 5000, button: true });
    },

    scrollToRenewal(){
      let el = document.querySelector('.renewal-button');
      if (el) el.scrollIntoView({ block: 'center', behavior: 'smooth' });
    },
  },

  watch: {
  	selectedLanguage: {
  		async handler() {
  			this.user.courses = await this.getUserCourses();
  		}
  	}
  }
}
</script>

<style>
.divider {
  margin: 30px 0px;
  border-bottom: 0.5px solid grey;
}
</style>

<style scoped>
.disabled {
  opacity: 0.5;
  text-decoration: none;
  cursor: grab !important;
  pointer-events: none;
}
.profile-header {
  background-image: url(../../assets/images/page-header/profile.jpg);
}
.text-link:hover {
  color: #1b96cf;
}
.divider-light { border-bottom: 0.5px solid lightgrey; }

#welcome-video::-webkit-media-controls-fullscreen-button {
    display: none;
}


.howtostudy-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  color: darkolivegreen;
  font-weight: 900;
  text-align: center;
  background: greenyellow;
  border: 1px solid darkolivegreen;
  border-radius: 20px;
  font-size: 10px;
}
.course-panel {
  border: 1px solid darkolivegreen;
  background-color: greenyellow;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
}

/* ANNOUNCEMENTS */

.announcement-wrapper {
  width: 100%;
  max-height: 300px;
  overflow: auto;
}
.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.current, .dot:hover {
  background-color: #717171;
}
.carousel-nav-btn:hover {
	visibility: visible;
	background-color: rgba(187, 187, 187, 1);
	border-radius: 5px;
	color: white;
	animation: fadeIn .5s;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  opacity: 1;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>

